import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroHouseImage from "../components/hero-house-image"
import MissionStatement from "../components/mission-statement"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="jumbotron">
      <div className="row">
        <div className="col-md-6 col-xl-4">
          <h1 className="display-4">Bringing Harmony to your Home</h1>
          <p className="lead">Poême Interiors provides interior decorating and consulting services to turn your house into a home.</p>
          <hr className="my-4" />
          <div className="text-center">
            <a className="btn btn-primary btn-lg" href="mailto:elaine@poemeinteriors.com">Request a Consultation</a>
          </div>
        </div>
        <div className="col-md-6 col-xl-8 d-none d-sm-none d-md-block">
          <HeroHouseImage></HeroHouseImage>
        </div>
      </div>
    </div>
    <section>
      <header>
        <h2>Our Mission Statement</h2>
      </header>
      <MissionStatement></MissionStatement>
    </section>
  </Layout>
)

export default IndexPage
